<template>
  <div style="width: 100%">
    <div class="advanced-settings-wrapper">
      <div class="advanced-settings-header exact-mapping">
        <div class="advanced-settings-title">
          <div class="title">
            Selected mapping
          </div>
          <div class="subtitle">
            Instance which source is mapped to
          </div>
        </div>
      </div>
      <div class="horizontal-line exact-mapping" />
    </div>
    <div class="suggested-mapping-huddle-info-wrapper exact-mapping">
      <div
        class="suggested-mapping-huddle-info"
      >
        <div class="logo-block exact-mapping">
          <img
            src="/images/icons/betgenius.png"
            alt="betgenius"
            v-if="feed === 'betgenius'"
          >
          <img
            src="/images/icons/betradar.png"
            alt="betgenius"
            v-if="feed === 'betradar'"
          >
          <img
            src="/images/icons/lsports.png"
            alt="lsports"
            v-if="feed === 'lsports'"
          >
          <Icon
            v-if="feed === 'huddle'"
            :name="'huddle'"
          />
        </div>
        <div class="huddle-info-details-wrapper">
          <div class="details-name">
            {{ name }}
          </div>
          <div
            v-if="additionalInfo"
            class="details-additional"
          >
            {{ additionalInfo }}
          </div>
        </div>
      </div>
      <div class="unmap-button-wrapper">
        <div
          class="unmap-button"
          @click="() => confirmUnmapping = true"
        >
          <Icon :name="'broken-link'" />
          Unmap
        </div>
      </div>
    </div>
  </div>
  <div
    class="unmap-confirmation-popup"
    v-if="confirmUnmapping"
  >
    <div class="header">
      {{ popupTitle }}
      <div
        class="icon-button"
        @click="() => confirmUnmapping = false"
      >
        <Icon :name="'x'" />
      </div>
    </div>
    <div>You'll be able to map source again.</div>
    <div class="unmap-buttons">
      <div
        class="unmap-button"
        @click="() => confirmUnmapping = false"
      >
        Cancel
      </div>
      <div
        class="unmap-button confirm"
        @click="confirmAction"
      >
        Yes, unmap
      </div>
    </div>
  </div>
  <div
    class="back-cover"
    v-if="confirmUnmapping"
  />
</template>

<script>
import {
  onMounted,
  onBeforeUnmount,
  ref,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    mappingId: {
      required: true,
      type: String,
    },
    feed: {
      required: true,
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    additionalInfo: {
      required: false,
      type: String,
      default: () => '',
    },
    sport: {
      required: false,
      type: String,
      default: () => '',
    },
    mappingType: {
      required: false,
      type: String,
      default: () => '',
    },
  },
  emits: ['closePopup'],
  setup(props, { emit }) {
    const store = useStore();
    const confirmUnmapping = ref(false);

    const popupTitle = computed(() => {
      let title = 'Unmap the ';
      switch (props.mappingType) {
      case 'EVENTS_MAPPING':
        title += 'event';
        break;
      case 'COMPETITORS_MAPPING':
        title += 'competitor';
        break;
      case 'REGIONS_MAPPING':
        title += 'region';
        break;
      case 'COMPETITIONS_MAPPING':
        title += 'competition';
        break;
      case 'PLAYERS_MAPPING':
        title += 'player';
        break;
      default:
        break;
      }
      return `${title}?`;
    });

    const confirmAction = () => {
      const payload = {
        id: props.id,
        mappingId: props.mappingId,
        sport: props.sport,
        mappingType: props.mappingType,

      };
      store.dispatch('unmapMapping', payload)
        .then(() => {
          confirmUnmapping.value = false;
          const message = 'Event has been successfully unmapped';
          store.dispatch('addNotification', {
            message,
            type: 'success',
            duration: 5000,
          });
          emit('closePopup', 'reload');
        });
    };

    const onEscEventListener = (e) => {
      if (e.key === 'Escape') {
        if (confirmUnmapping.value) {
          confirmUnmapping.value = false;
        } else {
          emit('closePopup');
        }
      }
    };
    onMounted(() => document.addEventListener('keydown', onEscEventListener));
    onBeforeUnmount(() => document.removeEventListener('keydown', onEscEventListener));
    return {
      popupTitle,
      confirmUnmapping,
      confirmAction,
    };
  },
};
</script>

<style lang="scss">
.suggested-mapping-huddle-info {
  width: 80%;
  .huddle-info-details-wrapper {
    width: calc(100% - 52px);
    .details-name,
    .details-additional {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
  .unmap-confirmation-popup {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 320px;
    height: 160px;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    background: #FFFFFF;
    z-index: 103;
    padding: 16px;

    .header {
      width: 100%;
      height: 50px;
      position: relative;
      font-size: 16px;
      font-weight: 600;
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      .icon-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 33px;
        width: 33px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
          height: 16px;
          width: 16px;

          svg {
            stroke: #6D6D6D;
            fill: #6D6D6D;
          }
        }
      }
    }

    .body {
      height: 17px;
      line-height: 17px;
    }

    .unmap-buttons {
      height: 33px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 12px;

      .unmap-button {
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 12px;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }

        &.confirm {
          margin-left: 16px;
          color: #ffffff;
          background-color: #003C3C;
        }
      }
    }
  }

  .back-cover {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 102;
    left: 0;
    top: 0;
  }
</style>
